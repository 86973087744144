/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect  } from 'react';
import { TECollapse, TERipple } from "tw-elements-react";

export default function Hero() {
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(0); // Add a key state to force re-render


  const toggleShow = () => setShow(!show);

  // Effect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      // This sets a new key on resize, which forces re-render
      setKey(prevKey => prevKey + 1);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
    <div className="flex flex-col sm:flex-row items-center gap-4 py-4">
      <div className="flex justify-center items-center">
        <img src='../images/dalmo-teal.png' alt="Dalmo Mendonça" className="rounded-full w-12 h-12 sm:w-auto sm:h-auto" style={{ maxWidth: '12rem', width: '25vw', height: 'auto', maxHeight: '12rem' }} />
      </div>
      <div className="sm:text-left content-center">
        <h1 className="bg-gradient-to-r from-teal-500 via-rose-800 to-red-600 bg-clip-text text-2xl sm:text-5xl font-extrabold ">
          <span className="text-transparent">Hi, I'm Dalmo</span> <TERipple rippleColor="light" rippleDuration="3000">❤️</TERipple>
        </h1>
        <h1 className="text-lg sm:text-4xl font-bold">
          <span>My mission in life is to </span>
          <TERipple rippleColor="light" rippleDuration="3000">
            <a className="inline-block text-rose-500 transition duration-150 ease-in-out cursor-pointer" onClick={toggleShow}>
            love & learn to love well.
            </a>
          </TERipple>
        </h1>
        <TECollapse show={show} key={key}>
          <div className="container py-4 p-6" onClick={toggleShow}>
            <div className="text-left text-justify">
              <p className="sm:text-xl mb-4">My joy is to serve others with the talents God gave me, the skills I’ve developed, and the expertise built up from pursuing my curiosity.</p>
              <p className="mb-4">I’m not called to work at just one job. So I’m often in search of new, meaningful ways to allocate my time and energy.</p>
              <p className="mb-4">Here is a selection of my strongest offerings: the kind of work that I excel in and that excites me.</p>
              <p className="mb-4">Schedule a free 30-minute online consultation with me to discuss how I might be able to serve you.</p>
            </div>
          </div>
        </TECollapse>
      </div>
    </div>

    


    </>
  );
}
