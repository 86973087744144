import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
      <nav className="flex justify-between items-center px-6 py-4 bg-black text-white">
        <div className="font-bold text-xl">
          <Link to="/" className="hover:text-gray-300">
            Dalmo Mendonça
          </Link>
        </div>
        
        <div>
          <Link to="/gift" className="px-4 hover:text-gray-300">The Gift</Link>
        </div>
        
      </nav>
    );
  }
  

  
export default Navbar;
