import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Gift from './components/Gift';

<Route path="/gift/:giftName" element={<Gift />} />

function App() {
  return (
    <Router>
      <div className="App h-screen flex flex-col">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gift" element={<Gift />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
