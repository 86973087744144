// src/data/offeringsData.js
const offeringsData = [
    {
        id: 1,
        title: 'Healthcare IT Consulting',
        summary: 'Over the past 10 years, I have made a career out of solving complex technical problems and bringing visions to life in the Epic space.',
        icon: '../images/healthcare-it.png',
        story: 
            `I started at Epic as an Ambulatory TS in the summer of 2012, at just 19 years old, with a college diploma in hand and a dream: to make an impact on patient care. The MyChart team eventually recruited me for my ability to learn quickly and lead effectively, and I fell in love with patient-facing technology. After a brief stint at a startup in NYC where I learned all about project and team management, I started my consulting career helping the large healthcare organizations tackle their biggest challenges.

            Now that I've now been doing Epic work for over a decade, what I've found is that the key to amazing results is actually helping others do their jobs better. Here's what I bring to the table: a commitment to setting my team up for long-term success, a bird's eye view of technical challenges informed by deep experience in integrated areas, and expertise in conflict resolution and interpersonal communication.`.replace(/^[ \t]+/gm, ''),
        case: 'I am looking for my next Epic contract. Get to know my track record of success and see whether we\'re a good match to work together on your next project, install, or upgrade.',
        files: {
            resume: '../files/Dalmo Mendonca Resume 2024.pdf',
            resumetxt: '../files/Dalmo Mendonca Resume 2024.txt'
        }
    },
    {
        id: 11,
        title: 'Integral Practice Consulting',
        summary: 'I help individuals, businesses, and communities apply the rich lessons from Integral Theory to their most significant challenges.',
        icon: '../images/integral-practice-consulting.png',
        story: '',
        case: ''
    },
    {
        id: 15,
        title: 'AI Consulting',
        summary: 'I utilize the latest AI tools to develop innovative solutions that tackle complex challenges across various sectors.',
        icon: '../images/ai-consulting.png',
        story: '',
        case: ''
    },
    {
        id: 4,
        title: 'Spiritual Direction',
        summary: 'I listen to the Spirit with people as one way to deepen their relationship with God, fostering spiritual growth through reflective dialogue and discernment.',
        icon: '../images/spiritual-direction.png'
    },
    {
        id: 8,
        title: 'Intentional Psychedelic Sessions',
        summary: 'I create and hold sacred space for intentional psychedelic journeys with cannabis, psilocybin, LSD, and others.',
        icon: '../images/entheogenic-facilitation.png',
        story: 'I saw God. That\'s the best language I can come up with to describe my first Ayahuasca journey in 2017. Since then, I\'ve studied and trained to facilitate similar experiences for others with psychedelic substances like ayahuasca, mushrooms, LSD, and even cannabis.'
    },
    {
        id: 5,
        title: 'Outreach to People at the Margins',
        summary: 'Dedicated to bridging gaps in society, I engage directly with marginalized communities to advocate for and support their needs.',
        icon: '../images/outreach.png',
        story: '',
        case: ''
    },
    {
        id: 6,
        title: 'Bible Teaching & Preaching',
        summary: 'I bring ancient texts to life by the power of the Holy Spirit with engaging and insightful interpretations that resonate in today\'s world.',
        icon: '../images/bible-teaching.png',
        story: '',
        case: ''
    },
    {
        id: 7,
        title: 'Prayer Ministry',
        summary: 'I intercede through prayer, seeking Christ\'s miraculous healing and transformative power to manifest in the lives of those I serve.',
        icon: '../images/prayer-ministry.png',
        story: '',
        case: ''
    },
    {
        id: 10,
        title: 'Ministry of Presence',
        summary: 'I show up and commit to being present with people in the midst of chaos and difficult situations, holding space as a witness.',
        icon: '../images/ministry-of-presence.png',
        story: '',
        case: ''
    },
    {
        id: 9,
        title: 'Meditation Guidance',
        summary: 'I help people get grounded, transcend the mind, and access new states of being with gentle guided meditations.',
        icon: '../images/meditation-guidance.png',
        story: '',
        case: ''
    },
    {
        id: 13,
        title: 'Connection Coaching',
        summary: 'I coach individuals on how to create meaningful relationships in both personal and professional settings.',
        icon: '../images/connection-coaching.png',
        story: '',
        case: ''
    },
    {
        id: 14,
        title: 'Authentic Relating Facilitation',
        summary: 'I facilitate sessions where individuals can explore authentic expression and build genuine connections with others.',
        icon: '../images/authentic-relating-facilitation.png',
        story: '',
        case: ''
    },
    {
        id: 2,
        title: 'Project Management',
        summary: 'I manage cross-functional, high-visibility projects to deliver top results on time and under budget by leveraging data & facilitating difficult conversations.',
        icon: '../images/project-management.png'
    },
    {
        id: 3,
        title: 'Career Mentorship',
        summary: 'I mentor mid-career professionals looking to grow in expertise, management, and leadership.',
        icon: '../images/career-mentorship.png',
        story: '',
        case: ''
    },
    {
        id: 12,
        title: 'Community Management',
        summary: 'I foster engaging and vibrant online communities, managing interactions and ensuring a thriving collaborative environment.',
        icon: '../images/community-management.png',
        story: '',
        case: ''
    },
    {
        id: 16,
        title: 'App & Website Creation',
        summary: 'I design and develop custom apps and websites that provide seamless user experiences and drive engagement.',
        icon: '../images/website-design.png',
        story: '',
        case: ''
    },
    {
        id: 17,
        title: 'AI Art & Design',
        summary: 'I create unique, visually captivating AI-generated images that push the boundaries of traditional art and design.',
        icon: '../images/ai-art.png',
        story: '',
        case: ''
    },
    {
        id: 20,
        title: 'Copy Editing',
        summary: 'I enhance written content by refining its clarity, coherence, and grammatical precision, ensuring it communicates its intended message effectively.',
        icon: '../images/copy-editing.png',
        story: '',
        case: ''
    },
    {
        id: 18,
        title: 'Translation',
        summary: 'I translate texts to and from English, Spanish, Portuguese, and Italian with precision of language and attention to context.',
        icon: '../images/translation.png',
        story: '',
        case: ''
    },
    {
        id: 19,
        title: 'Language Learning',
        summary: 'I help people make significant progress with their language learning efforts by focusing on winning strategies at every level.',
        icon: '../images/language-learning.png',
        story: '',
        case: ''
    },
    {
        id: 21,
        title: 'Public Speaking & Storytelling',
        summary: 'I craft and deliver engaging speeches and stories that captivate audiences and convey powerful messages.',
        icon: '../images/public-speaking.png',
        story: '',
        case: ''
    },
    {
        id: 22,
        title: 'Vacation Planning',
        summary: 'I meticulously plan and curate personalized vacations that offer unforgettable experiences tailored to individual preferences.',
        icon: '../images/vacation-planning.png',
        story: '',
        case: ''
    },
    {
        id: 23,
        title: 'Music Curation',
        summary: 'I create custom playlists that fit specific moods and occasions because I believe music can be a beautiful companion to almost every moment in life.',
        icon: '../images/music-curation.png',
        story: '',
        case: ''
    },
    {
        id: 24,
        title: 'Teaching & Tutoring',
        summary: 'I help dedicated students from 1st grade through college catch up or get ahead on a broad range of subjects with engaging explanations and customized practice.',
        icon: '../images/teaching-and-tutoring.png',
        story: '',
        case: ''
    },
    {
        id: 25,
        title: 'Poetry Writing',
        summary: 'I put words to things for which there is no language--compelled nonetheless.',
        icon: '../images/poetry-writing.png',
        story: '',
        case: ''
    },
    {
        id: 26,
        title: 'Something Else',
        summary: 'I have many other gifts and talents that just might be what you need! I am always looking for new arrows to add to my quiver.',
        icon: '../images/something-else.png',
        story: '',
        case: ''
    }
];

export default offeringsData;
