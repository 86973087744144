import React, { useState } from 'react';
import { Drawer } from 'vaul';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import giftsData from '../data/giftsData';


function Gifts() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedGift, setSelectedGift] = useState(null);
    const [expandedAlbum, setExpandedAlbum] = useState(null);

    const viewDetails = (gift) => {
        setSelectedGift(gift);
        setIsOpen(true);
        setExpandedAlbum(null);
    };

    const toggleAlbum = (albumTitle) => {
        setExpandedAlbum(expandedAlbum === albumTitle ? null : albumTitle);
    };

    const getGalleryImages = (album) => {
        return album.images.map(img => ({
            original: `${album.url}/${img}`,
            thumbnail: `${album.url}/${img}`,
            loading: 'eager'
        }));
    };

    return (
        <div className='flex-grow sm mx-auto py-4 px-4'>
          <div className="px-4 py-8">
              <h2 className="text-xl my-8">Here you will find a selection of my creative work, which I give to you entirely for free, no questions asked.</h2>
              <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                  {giftsData.map(gift => (
                      <div key={gift.id} className="flex content-start py-2 px-2 max-w-3xl bg-white rounded-xl shadow-lg space-y-2 items-center sm:space-y-0 space-x-4 sm:space-x-6 cursor-pointer hover:shadow-teal-600" onClick={() => viewDetails(gift)}>
                          <img className="block h-24 rounded-full mx-0 shrink-0" src={gift.icon} alt={gift.title} />
                          <p className="text-lg text-black font-semibold justify-self-left text-left sm:text-justify px-2">{gift.title}</p>
                      </div>
                  ))}
              </div>
          </div>
          <div className="container grid grid-cols-1 px-4 py-8">
            <div className='grid grid-cols-1 grid-rows-2 gap-3 block mx-0 px-8 py-8 max-w-3xl bg-white rounded-xl shadow-lg space-y-2 justify-self-center'>
              <p className="sm:px-8 mb-4">
                As part of the gift economy, I am dependent on the good will, love, support, and generosity of those who receive these gifts and who also choose to give back. 
              </p>
              <button className="text-2xl text-rose-600 font-semibold rounded-full border border-rose-200 hover:text-white hover:bg-rose-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-rose-600 focus:ring-offset-2" onClick={() => window.open('https://venmo.com/dalmo', '_blank')}>
                Give Dalmo a Gift
              </button>       
            </div>
          </div>

          {selectedGift && (
              <Drawer.Root open={isOpen} onOpenChange={setIsOpen}>
                  <Drawer.Portal>
                      <Drawer.Overlay onClick={() => setIsOpen(false)} className="bg-black bg-opacity-50 fixed inset-0" />
                      <Drawer.Content className="flex flex-col fixed top-10 bottom-0 lg:left-1/4 lg:right-1/4 bg-white shadow-lg rounded-lg overflow inset-0">
                          <div className='w-full mx-auto flex flex-col items-center p-4 overflow-auto'>
                              <button onClick={() => setIsOpen(false)} className="absolute top-4 right-4 text-3xl">×</button>
                              <img src={selectedGift.icon} alt={selectedGift.title} className="w-32 h-32 rounded-full mb-4" />
                              <h2 className="text-xl font-bold">{selectedGift.title}</h2>
                              <p className="text-md text-slate-500 font-normal mb-4">{selectedGift.summary}</p>
                              {selectedGift.links && (
                                  <div className="grid grid-cols-2 gap-4 my-4 w-full">
                                    {selectedGift.links.map(link => (
                                        //<button key={link.title} className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4" onClick={() => window.open(link.url, '_blank')}>
                                        <a key={link.title} href={link.url} target="_blank" rel="noopener noreferrer" className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 text-center w-full block flex items-center justify-center">
                                          {link.title}
                                        </a>
                                        //</button>
                                    ))}
                                  </div>
                              )}
                              {selectedGift.albums && (
                                  <div className="grid grid-cols-2 gap-4 my-4 w-full">
                                    {selectedGift.albums.map(album => (
                                        <>
                                        <button key={album.title} className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 w-full block flex items-center justify-center" onClick={() => toggleAlbum(album.title)}>
                                          {album.title}
                                        </button>
                                        {expandedAlbum === album.title && (
                                          <Drawer.Root open={isOpen} onOpenChange={setIsOpen}>
                                            <Drawer.Portal>
                                              <Drawer.Overlay onClick={() => setIsOpen(false)} className="bg-black bg-opacity-75 fixed inset-0" />
                                              <Drawer.Content className="container flex flex-col content-center fixed top-20 bottom-0 left-0 right-0 bg-white shadow-lg rounded-lg overflow inset-0">
                                                <div className='max-h-fit w-full self-center p-4 overflow-auto'>
                                                  <ImageGallery items={getGalleryImages(album)} thumbnailPosition='left' showBullets='true' />
                                                </div>
                                              </Drawer.Content>
                                            </Drawer.Portal>
                                          </Drawer.Root>
                                        )}
                                        </>
                                      ))}
                                  </div>
                              )}
                          </div>
                      </Drawer.Content>
                  </Drawer.Portal>
              </Drawer.Root>
          )}
        </div>
    );
}

export default Gifts;
