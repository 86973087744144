import React from 'react';

function Footer() {
  return (
    <footer className="bg-black text-white p-2 text-center bottom-0 w-full">
      Copyright © 2024 by Dalmo
    </footer>
  );
}

export default Footer;
