import React, { useState } from 'react';
import { Drawer } from 'vaul';
import Hero from './Hero';
import Offering from './Offering';
import offeringsData from '../data/offeringsData';

function Home() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOffering, setSelectedOffering] = useState(null);
    const viewDetails = (offering) => {
        setSelectedOffering(offering);
        setIsOpen(true);
    };

    return (
        <>
        <div className="container flex-grow sm:mx-auto sm:py-4 sm:px-4">
            <Hero />

            <div className="container grid grid-cols-1 md:grid-cols-2 gap-3">
                {offeringsData.map(offering => (                   
                    <div key={offering.id} className="flex content-start py-2 px-2 max-w-3xl bg-white rounded-xl shadow-lg space-y-2 items-center sm:space-y-0 space-x-4 sm:space-x-6 cursor-pointer hover:shadow-rose-200" onClick={() => viewDetails(offering)}>
                        <div className="block mx-0 shrink-0 flex-none justify-center items-center">
                            <img className="block mx-auto h-24 rounded-full sm:mx-0 sm:shrink-0" src={offering.icon} alt={offering.title} />
                            <button className="px-6 py-1 text-sm text-teal-600 font-semibold rounded-full border border-teal-200 hover:text-white hover:bg-teal-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2">
                                View
                            </button>
                        </div>
                        <div className="text-left sm:text-justify px-2">
                            <div className="space-y-0.5 items-start">
                                <p className="text-lg text-black font-semibold">
                                    {offering.title}
                                </p>
                                <p className="text-md text-slate-500 font-normal">
                                    {offering.summary}
                                </p>
                            </div>
                        </div>
                    
                    </div>
                ))}
            </div>

            {selectedOffering && (
                <Drawer.Root open={isOpen} onOpenChange={setIsOpen}>
                    <Drawer.Portal>
                        <Drawer.Overlay onClick={() => setIsOpen(false)} className="bg-black bg-opacity-50 fixed inset-0" />
                        <Drawer.Content className="flex flex-col fixed top-10 bottom-0 lg:left-1/4 lg:right-1/4 bg-white shadow-lg rounded-lg">
                            <div className='max-w-max w-full mx-auto flex flex-col p-4 overflow-auto'>
                                <Offering offering={selectedOffering} onClose={() => setIsOpen(false)} />
                            </div>
                        </Drawer.Content>
                    </Drawer.Portal>
                </Drawer.Root>
            )}
        </div>
        </>
    );
}

export default Home;
